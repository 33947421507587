import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserModel } from '../models/user.model';

import { map } from 'rxjs/operators';
import { PersonModel } from '../models/person.model';

@Injectable({
  providedIn: 'root'
})
export class PeopleService {

  //private url = 'http://www.ictapp.es/api/natural-persons';
  private url = 'http://localhost:8080/api/natural-people';

  private userToken;

  constructor(private http: HttpClient) {

  }

  logout() {

  }

  getPeople() {
    return this.http.get<PersonModel[]>(
      `${ this.url }`,
      this.headers
    );
  }

  get token(): string {
    return localStorage.getItem('token') || '';
  }

  get headers() {
    return {
      headers: {
        'Authorization': 'Bearer ' + this.token
      }
    }
  }
}
