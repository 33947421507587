import { Component, OnInit } from '@angular/core';
import { PersonModel } from 'src/app/models/person.model';
import { PeopleService } from 'src/app/services/people.service';

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.css']
})
export class PeopleComponent implements OnInit {

  public people: PersonModel[] = [];
  public cargando: boolean = true;

  constructor(private peopleService: PeopleService) { }

  ngOnInit() {

    this.cargando = true;

    this.peopleService.getPeople()
      .subscribe(people => {
        this.cargando = false;
        this.people = people;
        console.log(this.people);
      });
  }

}
