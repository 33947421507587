import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { UserModel } from '../../models/user.model';
import { AuthService } from '../../services/auth.service';

import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  user: UserModel;

  constructor(private auth: AuthService,
              private router: Router) {

    this.user = new UserModel();

  }

  ngOnInit() {
  }

  login( form: NgForm) {

    if (form.invalid) { return; }

    // Swal.fire({
    //   allowOutsideClick: false,
    //   type: 'info',
    //   text: 'Espere por favor ...'
    // });

    Swal.fire({
      allowOutsideClick: false,
      text: 'Espere por favor',
      icon: 'info'
    });

    Swal.showLoading();

    this.auth.login(this.user).subscribe( resp => {

      console.log(resp);
      Swal.close();
      this.router.navigateByUrl('/people');

    }, (err) => {

      console.log(err);
      Swal.fire({
        text: err.error.mensaje,
        title: 'Error',
        icon: 'error'
      });

    });

  }

}
