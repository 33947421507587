import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserModel } from '../models/user.model';

import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  //private url = 'http://www.ictapp.es/api/login';
  private url = 'http://localhost:8080/api/login';

  private userToken;

  constructor(private http: HttpClient) {
    this.userToken = this.readToken();
  }

  logout() {

  }

  login( user: UserModel ) {
    const authData = {
      email: user.email,
      password: user.password
    };

    return this.http.post(
      `${ this.url }`,
      authData
    ).pipe(
      map( resp => {
        const token = 'token';
        this.saveToken( resp[token] );
        return resp;
      })
    );
  }

  newUser( user: UserModel) {

    const authData = {
      email: user.email,
      password: user.password,
      returnSecureToken: true
    };

    return this.http.post(
      `${ this.url }`,
      authData
    );
  }

  private saveToken(token: string) {
    this.userToken = token;
    localStorage.setItem('token', token);
  }

  readToken() {
    if (localStorage.getItem('token')) {
      this.userToken = localStorage.getItem('token');
    } else {
      this.userToken = '';
    }
    return this.userToken;
  }

  estaAutenticado(): boolean {
    return this.userToken.length > 2;
  }
}
